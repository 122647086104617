/*

Lava Landing Page

https://templatemo.com/tm-540-lava-landing-page

*/

/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. welcome
06. features
07. testimonials
08. contact
09. footer
10. preloader

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 15px;
  line-height: 25px;
  color: #888;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #fff;
  font-family: 'Poppins', sans-serif;
}

::selection {
  background: #49708A;
  color: #fff;
}

::-moz-selection {
  background: #49708A;
  color: #fff;
}

@media (max-width: 991px) {
  html, body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

a.main-button-slider {
  font-size: 14px;
  border-radius: 25px;
  padding: 15px 25px;
  background-color: #5A8CB1;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

a.main-button-slider:hover {
  background-color: #49708A;
}

a.main-button {
  font-size: 14px;
  border-radius: 25px;
  padding: 15px 25px;
  background-color: #5A8CB1;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

a.main-button:hover {
  background-color: #49708A;
}

button.main-button {
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 25px;
  padding: 15px 25px;
  background-color: #5A8CB1;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

button.main-button:hover {
  background-color: #49708A;
}


.center-heading {
  margin-bottom: 70px;
}

.center-heading h2 {
  margin-top: 0px;
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  color: #1e1e1e;
  margin-bottom: 25px;
}

.center-heading h2 em {
  font-style: normal;
  color: #5A8CB1;
}

.drawer-heading h3 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: end;
  font-style: normal;
  color: #646464;
}

.drawer-heading h3 .active{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: normal;
  color: #5A8CB1;
}

.center-heading p {
  font-size: 15px;
  color: #888;
  text-align: center;
}


/* 
---------------------------------------------
header
--------------------------------------------- 
*/

.background-header {
  background-color: #fff;
  height: 80px!important;
  position: fixed!important;
  top: 0px;
  left: 0px;
  right: 0px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.15)!important;
}

.background-header .logo,
.background-header .main-nav .nav li a {
  color: #1e1e1e!important;
}

.background-header .main-nav .nav li:hover a {
  color: #5A8CB1!important;
}

.background-header .nav li a.active {
  color: #5A8CB1!important;
}

.header-area {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  height: 100px;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

.header-area .main-nav {
  min-height: 80px;
  background: transparent;
}

.header-area .main-nav .logo {
  line-height: 80px;
  color: #191a20;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}


.header-area .main-nav .nav {
  float: left;
  margin-top: 27px;
  margin-left: 15%;
  margin-right: 0px;
  background-color: #fff;
  padding: 0px 20px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.header-area .main-nav .nav li {
  padding-left: 20px;
  padding-right: 20px;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 0px;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 500;
  font-size: 13px;
  color: #7a7a7a;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
}

.header-area .main-nav .nav li:hover a {
  color: #5A8CB1;
}

.header-area .main-nav .nav li.submenu {
  position: relative;
  padding-right: 35px;
}

.header-area .main-nav .nav li.submenu:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 12px;
  color: #7a7a7a;
  position: absolute;
  right: 18px;
  top: 12px;
}

.header-area .main-nav .nav li.submenu ul {
  position: absolute;
  width: 200px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  top: 40px;
  opacity: 0;
  transform: translateY(-2em);
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
}

.header-area .main-nav .nav li.submenu ul li {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.header-area .main-nav .nav li.submenu ul li a {
  display: block;
  background: #fff;
  color: #7a7a7a!important;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 13px;
  border-bottom: 1px solid #f5f5f5;
}

.header-area .main-nav .nav li.submenu ul li a:before {
  content: '';
  position: absolute;
  width: 0px;
  height: 40px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: #5A8CB1;
}

.header-area .main-nav .nav li.submenu ul li a:hover {
  background: #fff;
  color: #5A8CB1!important;
  padding-left: 25px;
}

.header-area .main-nav .nav li.submenu ul li a:hover:before {
  width: 3px;
}

.header-area .main-nav .nav li.submenu:hover ul {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 23px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #1e1e1e;
}

.header-area.header-sticky {
  min-height: 80px;
}

.header-area.header-sticky .nav {
  margin-top: 20px !important;
}

.header-area.header-sticky .nav li a.active {
  color: #5A8CB1;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 12px;
    padding-right: 12px;
  }
  .header-area .main-nav:before {
    display: none;
  }
}

@media (max-width: 991px) {
  .logo {
    text-align: center;
    float: none!important;
  }
  .header-area {
    background-color: #f7f7f7;
    padding: 0px 15px;
    height: 80px;
    box-shadow: none;
    text-align: center;
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-left: 30px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    margin-top: 0px !important;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }
  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #5A8CB1!important;
  }
  .header-area .main-nav .nav li.submenu ul {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
  }
  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }
  .header-area .main-nav .nav li.submenu ul.active {
    height: auto !important;
  }
  .header-area .main-nav .nav li.submenu:after {
    color: #3B566E;
    right: 25px;
    font-size: 14px;
    top: 15px;
  }
  .header-area .main-nav .nav li.submenu:hover ul, .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

@media (min-width: 992px) {
  .header-area .main-nav .nav {
    display: flex !important;
  }
}


/* 
---------------------------------------------
welcome
--------------------------------------------- 
*/
.welcome-area {
  width: 100%;
  height: 100vh;
  min-height: 793px;
  /* background-image: url(../images/banner-bg.png);
  background-repeat: no-repeat;
  background-position: right top; */
}

.welcome-area .background-img {
  width: 100%;
  height: 100vh;
  background-color: #49708A;
  background-image: url(../images/banner-bg.png);
  background-repeat: no-repeat;
  background-position: right top;
  z-index: 1;
}

.background-img {
  position: absolute; /* Set position relative to manage layout */
  /* width: 100%; Full width */
  height: 85vh; /* Default height for larger screens */
  z-index: 99; /* Ensure the image stays behind content */
  top: 0%;
  right: 0%;

}

.background-img img {
  width: auto; /* Full width */
  height: 85vh; /* Full height */
  object-fit: cover; /* Maintain aspect ratio */
  opacity: 0.8; /* Set the opacity */
}

.welcome-area {
  position: relative; /* Keep content above the background image */
  z-index: 1; /* Ensure the text is above the image */
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .background-img img{
    height: 45vh; /* Height for smaller screens */
  }
}


.welcome-area .header-text .left-text {
  position: relative;
  align-self: center;
  margin-top: 35%;
  transform: perspective(1px) translateY(-30%) !important;
}

.welcome-area .header-text h1 {
  font-weight: 900;
  font-size: 52px;
  line-height: 80px;
  letter-spacing: 1px;
  margin-bottom: 30px;
  color: #191a20;
}

.welcome-area .header-text h1 em {
  font-style: normal;
  color: #5A8CB1;
}

.welcome-area .header-text p {
  font-weight: 300;
  font-size: 17px;
  color: #7a7a7a;
  line-height: 30px;
  letter-spacing: 0.25px;
  margin-bottom: 40px;
  position: relative;
}

@media (max-width: 1420px) {
  .welcome-area .header-text {
    width: 70%;
    margin-left: 5%;
  }
}

@media (max-width: 1320px) {
  .welcome-area .header-text .left-text {
    margin-top: 50%;
  }
  .welcome-area .header-text {
    width: 50%;
    margin-left: 5%;
  }
}

@media (max-width: 1140px) {
  .welcome-area {
    height: auto;
  }
  .welcome-area .header-text {       
    width: 92%;
    padding-top: 793px;
  }
  .welcome-area .header-text .left-text {
    margin-top: 5%;
    transform: none !important;
  }
}
@media (max-width: 810px) {
  .welcome-area {
    background-size: contain;
  }

  .welcome-area .header-text {
    padding-top: 100%;
    margin-left: 0;
  }
    
  .container-fluid .col-md-12 {
    margin-right: 0px;
    padding-right: 0px;
  }
}

/*
---------------------------------------------
about
---------------------------------------------
*/


#about {
  padding: 100px 0 0 0;
  position: relative;
  z-index: 9;
}

.about-area {
  width: 100%;
  /* background-color: #f7f7f7; */
}

.about-area .about-text {
  position: relative;
  align-self: center;
}

.about-area .about-text h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #5A8CB1;
}

.about-area .about-text h2 em {
  font-style: normal;
  color: #5A8CB1;
}

.about-area .about-text p {
  font-weight: 300;
  font-size: 17px;
  /* color: #7a7a7a; */
  line-height: 30px;
  letter-spacing: 0.25px;
  margin-bottom: 40px;
  position: relative;
}



@media (max-width: 992px) {
  .features-item {
    margin-bottom: 45px;
  }
}

.features-item {
  border-radius: 5px;
  padding: 60px 30px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.features-item h2 {
  z-index: 9;
  position: absolute;
  top: 15px;
  left: 45px;
  font-size: 100px;
  font-weight: 600;
  color: #fdefe9;
}

.features-item img {
  z-index: 10;
  position: relative;
  max-width: 100%;
  margin-bottom: 40px;
}

.features-item h4 {
  margin-top: 0px;
  font-size: 22px;
  color: #1e1e1e;
  margin-bottom: 25px;
}

.features-item .main-button {
  margin-top: 35px;
  display: inline-block;
}


/*
---------------------------------------------
promotion
---------------------------------------------
*/

.left-image-decor {
  background-image: url(../images/left-bg-decor2.png);
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  display: inline-block;
  height: 110%;
  margin-top: -200px;
}

#promotion {
  padding: 100px 0px;
}

#promotion .left-image {
  align-self: center;
}

#promotion .right-text ul {
  padding: 0;
  margin: 0;
}

#promotion .right-text ul li {
  display: inline-block;
  margin-bottom: 60px;
}

#promotion .right-text ul li:last-child {
  margin-bottom: 0px;
}

#promotion .right-text img {
  display: inline-block;
  float: left;
  margin-right: 30px;
}

#promotion .right-text h4 {
  font-size: 22px;
  color: #1e1e1e;
  margin-bottom: 15px;
  margin-top: 3px;
}


/*
--------------------------------------------
testimonials
--------------------------------------------
*/
.right-image-decor {
  background-image: url(../images/right-bg-decor2.png);
  background-repeat: no-repeat;
  background-position: right center;
  position: absolute;
  width: 100%;
  display: inline-block;
  height: 110%;
  margin-top: -200px;
}

#testimonials {
  padding-top: 100px;
  position: relative;
  z-index: 9;
}

#testimonials .item {
  position: relative;
}

#testimonials .item .testimonial-content {
  background: rgb(244,129,63);
  background: linear-gradient(145deg, rgba(90,140,177,1) 0%, rgba(63,104,129,1) 100%);;
  margin-left: 60px;
  padding: 40px 30px;
  text-align: center;
  border-radius: 5px;
}

#testimonials .item .testimonial-content ul li {
  display: inline-block;
  margin: 0px 1px;
}

#testimonials .item .testimonial-content ul li i {
  color: #fff;
  font-size: 13px;
}

#testimonials .item .testimonial-content h4 {
  font-size: 22px;
  margin-top: 20px;
  color: #fff;
}

#testimonials .item .testimonial-content p {
  color: #fff;
  font-style: italic;
  font-weight: 200;
  margin: 20px 0px 15px 0px;
}

#testimonials .item .testimonial-content span {
  color: #fff;
  font-weight: 200;
  font-size: 13px;
}

#testimonials .author img {
  max-width: 120px;
  margin-bottom: -60px;
}

.owl-carousel .owl-nav button.owl-next span {
  z-index: 10;
  top: 45%;
  border-radius: 10px;
  font-size: 46px;
  color: #fff;
  right: -210px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  background: rgb(244,129,63);
  background: linear-gradient(145deg, rgba(90,140,177,1) 0%, rgba(63,104,129,1) 100%);;
  position: absolute;
}

.owl-carousel .owl-nav button.owl-prev span {
  z-index: 9;
  top: 30%;
  border-radius: 10px;
  font-size: 46px;
  color: #fff;
  right: -150px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  background: rgb(244,129,63);
  background: linear-gradient(145deg, rgba(90,140,177,1) 0%, rgba(63,104,129,1) 100%);;
  position: absolute;
}

@media screen and (max-width: 1060px) {
  .owl-carousel .owl-nav {
    display: none!important;
  }
}

/* 
---------------------------------------------
footer
--------------------------------------------- 
*/

@media (max-width: 992px) {
  footer {
    padding-top: 0px!important;
    margin-top: 0px!important;
  }
  .contact-form {
    margin-bottom: 45px;
  }
  footer .right-content {
    text-align: center;
  }
  footer .footer-content {
    padding: 60px 0px!important;
    margin-top: -100px;
  }
}

footer {
  position: relative; /* Add position relative for absolute positioning of pseudo-element */
  /* margin-top: 100px; */
  padding-top: 100px;
  width: 100%;
  overflow: hidden; /* Prevent overflow from pseudo-element */
}

footer::before {
  content: ''; /* Required for pseudo-elements */
  position: absolute; /* Position it absolutely within the footer */
  top: 0; /* Align it to the top */
  left: 0; /* Align it to the left */
  right: 0; /* Align it to the right */
  bottom: 0; /* Align it to the bottom */
  background-image: url(../images/footer-bg2.png);
  background-repeat: no-repeat;
  background-position: center bottom; /* Center the background image */
  background-size: contain;
  opacity: 0.2; /* Set the opacity to 0.5 for transparency */
  z-index: 0; /* Place it behind the content */
}

footer > * {
  position: relative; /* Ensure footer content is above the background */
  z-index: 1; /* Bring the content above the pseudo-element */
}


footer .footer-content {
  padding: 0px 0px;
}

.contact-form {
  position: relative;
  z-index: 10;
  padding: 30px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  
}

footer .right-content {
  align-self: center;
}

.contact-form input,
.contact-form textarea {
  color: #777;
  font-size: 13px;
  border: 1px solid #eee;
  background-color: #f7f7f7;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 5px;
}

.contact-form textarea {
  resize: none;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.contact-form {
  text-align: center;
}

.contact-form ::-webkit-input-placeholder { /* Edge */
  color: #7a7a7a;
}

.contact-form :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #7a7a7a;
}

.contact-form ::placeholder {
  color: #7a7a7a;
}

.footer-content .right-content {
  color: #fff;
}

.footer-content .right-content h2 {
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 42px;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 1px;
}

.footer-content .right-content h2 em {
  font-style: normal;
  color: #5A8CB1;
}

.footer-content .right-content p {
  color: #FFF;
}

.footer-content .right-content a {
  color: #FFF;
}

.footer-content .right-content a:hover {
  color: #FC3;
}

footer .social {
  overflow: hidden;
  margin-top: 30px;
}

footer .social li:first-child {
  margin-left: 0px;
}

footer .social li {
  margin: 0px 7.5px;
  display: inline-block;
}

footer .social li a {
  color: #fff;
  text-align: center;
  background-color: transparent;
  border: 1px solid #fff;
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 50%;
  display: inline-block;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

footer .social li a:hover {
  background-color: #5A8CB1;
  border-color: #5A8CB1;
  color: #fff;
}

footer .copyright {
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
  color: #4a4a4a;
  letter-spacing: 0.88px;
  text-transform: capitalize;
}

footer .sub-footer {
  padding: 10px 0px;
  border-top: 1px solid rgba(0,0,0,0.5);
  text-align: center;
}

footer .sub-footer a {
	color: #FFF;
}

footer .sub-footer a:hover {
	color: #FC3;
}

footer .sub-footer p {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.5px;
}

@media (max-width: 991px) {
  footer .copyright {
    text-align: center;
  }
  footer .social {
    text-align: center;
  }
}

/* 
---------------------------------------------
preloader
--------------------------------------------- 
*/
#preloader {
  overflow: hidden;
  background-image: linear-gradient(145deg, #5A8CB1 0%, #49708A 100%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  color: #fff;
}

#preloader .jumper {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  margin: auto;
  width: 50px;
  height: 50px;
}

#preloader .jumper > div {
  background-color: #fff;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  opacity: 0;
  width: 50px;
  height: 50px;
  -webkit-animation: jumper 1s 0s linear infinite;
  animation: jumper 1s 0s linear infinite;
}

#preloader .jumper > div:nth-child(2) {
  -webkit-animation-delay: 0.33333s;
  animation-delay: 0.33333s;
}

#preloader .jumper > div:nth-child(3) {
  -webkit-animation-delay: 0.66666s;
  animation-delay: 0.66666s;
}

@-webkit-keyframes jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.submit-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
    /* Align items to the right */
  position: relative;
  margin-top: 15px;
}

.captcha-container {
  margin-bottom: 15px; /* Add space between CAPTCHA and button */
  align-items: flex-start;
}

#sendMessageButton {
  margin-right: 10px;
  
}



.drawer {
  transition: transform 0.3s ease-in-out;
}

.drawer-content {
  transform: translateX(100%); /* Start hidden off-screen */
}

.drawer-content.show {
  transform: translateX(0); /* Slide in */
}

.drawer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.drawer-heading {
  text-decoration: none;
  color: #000; /* Adjust color as needed */
}

.drawer-heading.active {
  font-weight: bold; /* Highlight active item */
}

/* Optional: Style for smooth appearance */
.scroll-to-section {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-section.show {
  opacity: 1; /* Fade in */
}

.drawer-heading {
  text-decoration: none;
  color: #000; /* Adjust color as needed */
  padding: 10px 15px; /* Add some padding for better spacing */
  border-radius: 5px; /* Slightly rounded corners */
  display: block; /* Make the link a block element */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

/* Add box shadow on hover */
.drawer-heading:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Soft shadow */
  transform: translateY(-2px); /* Slight lift effect */
}

/* Active state styling */
.drawer-heading.active {
  font-weight: bold; /* Highlight active item */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* More pronounced shadow for active item */
}
