.floating-whatsapp .floating-icon:hover {
    /* color: #000000; Specify the color you want on hover */
    transition: color 0.3s ease, transform 0.3s ease; /* Add smooth transitions for color and transform */
    transform: scale(1.1); /* Add a zoom effect on hover */
    
}

/* Set initial position slightly off-screen */
/* .contact-us {
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .floating-whatsapp:hover .contact-us {
    transform: translateX(0);
  }
   */